<template>
  <AppNavbar></AppNavbar>
    <router-view />

</template>

<script setup>

import AppNavbar from './components/AppNavbar.vue';

const detectDeviceType = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? 'Mobile'
    : 'Desktop';
console.log(detectDeviceType());

</script>

<style>
</style>

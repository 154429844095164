import AppHome from '@/components/AppHome.vue'
import MeusAgendamentos from '@/components/MeusAgendamentos.vue'
import VerAgendamentos from '@/components/VerAgendamentos.vue'
import Configurações from '@/components/configurações/Configurações.vue'
import AppLogin from '@/components/AppLogin.vue'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: AppHome
  },
  {
    path: '/meus-agendamentos',
    name: 'meus-agendamentos',
    component: MeusAgendamentos
  },
  {
    path: '/ver-agendamentos',
    name: 'ver-agendamentos',
    component: VerAgendamentos
  },
  {
    path: '/config',
    name: 'config',
    component: Configurações
  },
  {
    path: '/login',
    name: 'login',
    component: AppLogin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
    <div class="app">
        <div class="app2">
            <b-field label="Telefone">
                <b-input v-model="phone" :onchange="onChange" type="tel" id="phone" name="phone"
                    placeholder="(016) 9 9999-9999" pattern="([0-9]{3})[0-9]{5}-[0-9]{4}" required> </b-input>
            </b-field>
            <b-button type="is-primary"
                style="align-items: baseline; align-content: center; align-self: center; margin-bottom: 10px;"
                :onclick="verAgendamentos"><span class="mdil mdil-eye"></span> Ver agendamentos</b-button>

            <b-table :data="isEmpty ? [] : data">

                <b-table-column field="id" label="ID" width="40" v-slot="props">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="nome" label="Nome" v-slot="props">
                    {{ props.row.first_name }}
                </b-table-column>

                <b-table-column field="date" label="Data" centered v-slot="props">
                    <span class="tag is-success">
                        {{ new Date(props.row.date).toLocaleDateString() }}
                    </span>
                </b-table-column>


                <b-table-column field="date" label="Hora" centered v-slot="props">
                    <span class="tag is-success">
                        {{ new Date(props.row.date).toLocaleDateString() }}
                    </span>
                </b-table-column>

                <b-table-column field="date" label="Editar" centered v-slot="props">
                    <span class="tag is-success clickable-item" style="margin-right: 5px;">
                        <span class="mdil mdil-pencil"> </span>
                    </span>
                    <span class="tag is-danger clickable-item">
                        <span class="mdil mdil-delete"></span>
                    </span>
                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">Sem agendamentos</div>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';


const data = ref()

function verAgendamentos() {

}

function onChange() {
    let clean = this.phone.replace(/[^0-9]/g, '').substring(0, 11);
    if (clean.length > 0) {
        clean = clean.replace(/^(\d{3})(\d)/g, '($1) $2');
        clean = clean.replace(/(\d{5})(\d{1,4})/, '$1-$2');
        this.phone = clean;
    }
}
</script>
<style>
.container {
    display: flex;
    flex-direction: column;
}

.clickable-item {
    cursor: pointer !important;
}
</style>
<template>
    <b-navbar class="">
        <template #start>
            <b-navbar-item>
                <RouterLink to="/" class="nav-text"> <svg-icon type="mdi" :path="agendar"></svg-icon> Agendar
                </RouterLink>

            </b-navbar-item>
            <b-navbar-item>
                <RouterLink to="/meus-agendamentos" class="nav-text" ><svg-icon type="mdi"
                        :path="agendamentos"></svg-icon>Meus agendamentos</RouterLink>

            </b-navbar-item>
            <b-navbar-item>
                <RouterLink to="/ver-agendamentos" class="nav-text"><svg-icon type="mdi" :path="ver_horarios"></svg-icon> Ver agendamentos</RouterLink>

            </b-navbar-item>
            <b-navbar-item>
                <RouterLink to="/config" class="nav-text"><svg-icon type="mdi" :path="configurações"></svg-icon> Configurações
                </RouterLink>

            </b-navbar-item>
            <b-navbar-item>
                <RouterLink to="/login" class="nav-text"><svg-icon type="mdi" :path="login"></svg-icon> Entrar</RouterLink>

            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdilSettings, mdilEye, mdilCalendar, mdilFormatListBulleted, mdilLogin } from '@mdi/light-js';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'

const route = useRoute()

const permission = ref(false);

function checkPermission() {

}

onMounted(() => {
    checkPermission();
})

export default {
    name: "my-component",
    components: {
        SvgIcon
    },
    data() {
        return {
            permission: false,
            configurações: mdilSettings,
            agendamentos: mdilFormatListBulleted,
            ver_horarios: mdilEye,
            agendar: mdilCalendar,
            login: mdilLogin,

        }
    }
}
</script>
<style>
.neumorphism {
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 20px 20px 60px #d9d9d9,
        -20px -20px 60px #ffffff;
}

.button.right {
    left: 33%;
}

.nav-text {
    color: black;
}
</style>
<template>
    <div class="app">
        <div class="app2">
            <b-field label="Usuario">
                <b-input v-model="username" placeholder="Digite o seu nome" required>
                </b-input>
            </b-field>
            <b-field label="Senha">
                <b-input v-model="password" type="password" placeholder="Digite o seu nome" required password-reveal>
                </b-input>
            </b-field>
            <b-button class="is-primary" style="margin-left: 10px " :onclick="success"><span
                    class="mdil mdil-login"></span>Entrar</b-button>
        </div>
    </div>
</template>
<script>export default {
    data() {
        return {
        }
    },
    methods: {
        toast() {
            this.$buefy.toast.open('Something happened')
        },
        success() {
            this.$buefy.toast.open({
                message: 'Você entrou!',
                type: 'is-success'
            })
        },
        danger() {
            this.$buefy.toast.open({
                message: 'Senha incorreta',
                type: 'is-danger'
            })
        }
    }
}
</script>
<style></style>
<template>
    <b-field label="Bloquear dias da semana">

        <b-table :data="data.dias_bloqueados">
            <b-table-column field="dia" label="Dia" v-slot="props">
                {{ props.row.dia }}
            </b-table-column>
            <b-table-column field="state" label="Estado" v-slot="props">
                {{ props.row.state }}
            </b-table-column>
            <b-table-column field="editar" label="Editar" v-slot="props">
                <b-button :id="props.row.dia" :type="(props.row.state === 'bloqueado' ? 'is-success' : 'is-danger')"
                    @click="updateState(props.row)">{{ (props.row.state === 'bloqueado' ? 'desbloquear'
                        : 'bloquear') }}</b-button>
            </b-table-column>
        </b-table>
    </b-field>
</template>
<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        updateState(param) {
            if (!param || !param.dia) {
                console.error('Parâmetro inválido', param);
                this.$parent.danger('Parâmetro inválido! Parâmetro: ' + param);
                return;
            }
            for (let i = 0; i < this.data.dias_bloqueados.length; i++) {
                let key = this.data.dias_bloqueados[i];
                if (key.dia === param.dia) {
                    key.state = key.state === 'bloqueado' ? 'desbloqueado' : 'bloqueado';
                    this.$parent.success('Dia atualizado com sucesso!');
                    break;
                }
            }
        },
    }
}
</script>
<style></style>
<template>
    <div class="app">
        <div class="app2">
            <b-field label="Selecione uma data">
                <b-datepicker v-model="date" :@input="onChange()" placeholder="Selecione uma data..." locale="pt-BR">
                </b-datepicker>
            </b-field>

            <b-table :data="isEmpty ? [] : data">

                <b-table-column field="id" label="ID" width="40" v-slot="props">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="nome" label="Nome" v-slot="props">
                    {{ props.row.first_name }}
                </b-table-column>

                <b-table-column field="date" label="Data" centered v-slot="props">
                    <span class="tag is-success">
                        {{ new Date(props.row.date).toLocaleDateString() }}
                    </span>
                </b-table-column>


                <b-table-column field="date" label="Hora" centered v-slot="props">
                    <span class="tag is-success">
                        {{ new Date(props.row.date).toLocaleDateString() }}
                    </span>
                </b-table-column>

                <b-table-column field="date" label="Editar" centered v-slot="props">
                    <span class="tag is-success clickable-item" style="margin-right: 5px;">
                        <span class="mdil mdil-pencil"> </span>
                    </span>
                    <span class="tag is-danger clickable-item">
                        <span class="mdil mdil-delete"></span>
                    </span>
                </b-table-column>

                <template #empty>
                    <div class="has-text-centered">Sem agendamentos</div>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';


const date = ref();
const data = ref();

function onChange() {
    console.log("chamado")
}

</script>
<style>
.field {
    flex-direction: column;
}
</style>
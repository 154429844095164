<template>
    <b-field class="" label="Bloquear período">
        <b-tabs>
            <b-tab-item label="Adicionar">
                <b-datepicker v-model="periodos_bloqueados" placeholder="Selecione uma data..." locale="pt-BR" range>
                </b-datepicker>
                <b-button class="is-primary" style="margin-left: 10px; margin-top: 10px;" @click="onNewPeriodo"><span
                        class="mdil mdil-content-save"></span>Salvar</b-button>
            </b-tab-item>
            <b-tab-item label=" bloqueados">
                <b-table :data="data.periodos_bloqueados">
                    <b-table-column field="dia" label="Período" v-slot="props">
                        {{ props.row }}
                    </b-table-column>
                    <b-table-column field="editar" label="Deletar" v-slot="props">
                        <b-button :type="('is-danger')" @click="onDeletPeriodo(props.row)"><span
                                class="mdil mdil-delete" style="font-size: 20px;"></span>Deletar</b-button>
                    </b-table-column>
                </b-table>
            </b-tab-item>
        </b-tabs>
    </b-field>
</template>
<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            periodos_bloqueados: null,
            date: null,
            dateTwo: null,

        }

    },
    methods: {

        onDeletPeriodo(param) {
            if (!param || param === undefined) {
                this.$parent.danger('Parâmetro inválido! Parâmetro: ' + param);
                return;
            }
            this.data.periodos_bloqueados = this.data.periodos_bloqueados.filter(element => element !== param);
        },
        onNewPeriodo() {
            if (this.check()) {
                return;
            }
            this.createNewArray();
            this.clean();
            this.$parent.success('Periodo bloqueado com sucesso!');
        },
        createNewArray() {
            let array = [];
            array.push(...this.data.periodos_bloqueados);
            array.push(this.date.toLocaleDateString('pt-BR') + ' - ' + this.dateTwo.toLocaleDateString('pt-BR'));
            this.data.periodos_bloqueados = array;
        },
        clean() {
            this.periodos_bloqueados = null;
        },
        check() {
            if (!this.periodos_bloqueados || this.periodos_bloqueados === undefined || this.periodos_bloqueados === null) {
                this.$parent.danger('Preencha todos os campos!');
                return true;
            }
            this.date = new Date(this.periodos_bloqueados[0]);
            this.dateTwo = new Date(this.periodos_bloqueados[1]);
            if (!this.data.periodos_bloqueados || this.data.periodos_bloqueados == undefined) {
                this.data.periodos_bloqueados = [this.date.toLocaleDateString('pt-BR') + ' - ' + this.dateTwo.toLocaleDateString('pt-BR')];
                return true;
            }
        }
    }
}
</script>
<style></style>
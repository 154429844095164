<template>

    <b-field label="Bloquear horários indeterminadamente">
        <b-tabs>
            <b-tab-item label="Bloquear">
                <b-field label="Hora inicial">
                    <b-select v-model="bhi_start" placeholder="Selecione um horário">
                        <option v-for="option in data.hours" :value="option" :key="option.id">
                            {{ option }}
                        </option>
                    </b-select>
                </b-field>
                <b-field label="Hora final">
                    <b-select v-model="bhi_end" placeholder="Selecione um horário">
                        <option v-for="option in data.hours" :value="option" :key="option.id">
                            {{ option }}
                        </option>
                    </b-select>
                </b-field>

                <b-button class="is-primary" style="margin-left: 10px " :onclick="onBloqHorInd"><span
                        class="mdil mdil-content-save"></span>Salvar</b-button>
            </b-tab-item>
            <b-tab-item label="Bloqueados">
                <b-table :data="data.bhi">
                    <b-table-column field="hours" label="Horário" v-slot="props">
                        {{ props.row.start }} - {{ props.row.end }}
                    </b-table-column>
                    <b-table-column field="editar" label="Editar" v-slot="props">
                        <b-button type="is-danger" @click="onDeleteHI(props.row)"><span class="mdil mdil-delete"
                                style="font-size: 20px;"></span>Deletar</b-button>
                    </b-table-column>
                </b-table>
            </b-tab-item>
        </b-tabs>

    </b-field>
</template>
<script>
export default {
    props: {
        data: Object,
    },
    data() {
        return {
            bhi_start: null,
            bhi_end: null,
        }
    },
    methods: {
        onDeleteHI(param) {
            this.data.bhi = this.data.bhi.filter(
                element => element.start !== param.start && element.end !== param.end
            );
            this.$parent.success('Horário deletado com sucesso!');
        },
        onBloqHorInd() {
            if (!this.bhi_start || !this.bhi_end) {
                this.$parent.danger('Preencha todos os campos!');
                return;
            }
            this.updatedArray();
            this.clean();
            this.$parent.success('Horário bloqueado com sucesso!');
        },
        updatedArray() {
            this.data.bhi.push({ start: this.bhi_start, end: this.bhi_end });
            this.data.bhi = [...this.data.bhi];
        },
        clean() {
            this.bhi_start = null;
            this.bhi_end = null;
        }
    }
}
</script>
<style></style>
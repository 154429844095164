<template>
    <div class="app">
        <div class="app2">

            <BloquearPeriodo :data="data" />

            <!-- NOVA SEÇÃO-->
            <BloquearDiasDaSemana :data="data" />


            <!--  NOVA SEÇÃO -->
            <BloquearHorariosEspecificos :data="data" />
            <!-- NOVA SEÇÃO -->

            <BloquearHorariosIndeterminadamente :data="data" />


        </div>
    </div>
</template>
<script>
import BloquearDiasDaSemana from './BloquearDiasDaSemana.vue';
import BloquearHorariosEspecificos from './BloquearHorariosEspecificos.vue';
import BloquearHorariosIndeterminadamente from './BloquearHorariosIndeterminadamente.vue';
import BloquearPeriodo from './BloquearPeriodo.vue';


export default {
    components: {
        BloquearPeriodo,
        BloquearDiasDaSemana,
        BloquearHorariosEspecificos,
        BloquearHorariosIndeterminadamente
    },
    data() {
        return {
            bhi_start: null,
            bhi_end: null,
            data: {
                hours: [],
                horarios_bloqueados_inderteminadamente: [{ dia: '14/08/2024', start: '10:00', end: '12:00' }],
                horarios_bloqueados_especificos: [{ dia: '14/08/2024', start: '10:00', end: '12:00' }],
                periodos_bloqueados: ['14/08/2024 - 30/08/2024'],
                periodos_horarios: [{ dia: '14/08/2024', start: '12:30', end: '16:00' }],
                bhi: [{ start: '12:30', end: '16:00' }],
                dias_bloqueados: [
                    { dia: 'Segunda-feira', state: 'desbloqueado' },
                    { dia: 'Terça-feira', state: 'desbloqueado' },
                    { dia: 'Quarta-feira', state: 'desbloqueado' },
                    { dia: 'Quinta-feira', state: 'desbloqueado' },
                    { dia: 'Sexta-feira', state: 'desbloqueado' },
                    { dia: 'Sábado', state: 'bloqueado' },
                    { dia: 'Domingo', state: 'bloqueado' }
                ]
            }
        };
    },
    methods: {

        success(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-success'
            })
        },
        danger(message) {
            this.$buefy.toast.open({
                duration: 5000,
                message: message,
                type: 'is-danger'
            })
        }
    },
    mounted() {
        for (let i = 0; i < 24; i++) {
            this.data.hours.push(i + ":00");
            this.data.hours.push(i + ":30");
        }
    }
};
</script>
<style></style>
<template>
    <div class="app">
        <div class="app2 sizee">
            <b-field label="Nome">
                <b-input v-model="name" name="name" placeholder="Digite o seu nome" required>
                </b-input>
            </b-field>
            <b-field label="Telefone">
                <b-input v-model="phone" :onkeypress="onChange" type="tel" id="phone" name="phone"
                    placeholder="(016) 9 9999-9999" pattern="\([0-9]{3}\) [0-9]{5}-[0-9]{4}" required> </b-input>
            </b-field>
            <b-field label="Selecione uma data">
                <b-datepicker v-model="date" placeholder="Selecione uma data..." locale="pt-BR">
                </b-datepicker>
            </b-field>
            <b-field label="Selecione um horário">
                <b-field>
                    <b-select v-model="hour" placeholder="Horário">
                        <option :value="item" v-for="item in hours">{{ item }}</option>
                    </b-select>
                </b-field>
            </b-field>
            <b-field label="Selecione o tipo de serviço"
                style="align-items:  baseline !important; display: flex; position: relative; ">
                <b-radio v-model="radioButton" native-value="cabelo" type="is-primary">
                    Cabelo
                </b-radio>
                <b-radio v-model="radioButton" native-value="barba" type="is-primary ">
                    Barba
                </b-radio>
            </b-field>
            <b-button type="is-primary" @click="agendar">Agendar</b-button>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {

            phone: '',
            name: '',
            date: '',
            hour: '',
            radioButton: '',
            hours: ["10:30", "11:00"],
        }
    },
    methods: {
        success() {
            this.$buefy.toast.open({
                message: 'Something happened correctly!',
                type: 'is-success'
            })
        },
        danger(message) {
            this.$buefy.toast.open({
                duration: 5000,
                message: message,
                type: 'is-danger'
            })
        },

        onChange() {
            let clean = this.phone.replace(/[^0-9]/g, '').substring(0, 11);
            if (clean.length > 0) {
                clean = clean.replace(/^(\d{3})(\d)/g, '($1) $2');
                clean = clean.replace(/(\d{5})(\d{1,4})/, '$1-$2');
                this.phone = clean;
            }
        }
    },

    getAvaliableTimes() {
        fetch('backend.hugosilvaf.cloud', {
            method: ''
        });
    },

    agendar() {
        if (this.checkInputs()) {

        }

    },

    checkInputs() {
        if (this.name === '') {
            this.danger('Você deve digitar o seu nome completo');
            return false;
        }
        if (this.phone === '') {
            this.danger('Você deve digitar o número do seu telefone');
            return false;
        }
        if (this.date === '') {
            this.danger('Você deve selecionar uma data');
            return false;
        }
        if (this.hour === '') {
            this.danger('Você deve selecionar um horário');
            return false;
        }
        return true;
    },

}


</script>
<style>
.app {
    display: flex;
    justify-content: center;
    align-self: center !important;
    align-content: center !important;
    padding: 20px !;
    margin: 20px !important;
}

.app2 {
    flex-shrink: 1;
    flex-grow: 1;
    padding: 30px;
    margin: 20px !important;
    border-radius: 19px;
    background: #ffffff;
    box-shadow: 0px 0px 35px 7px rgba(0, 0, 0, 0.17);
    
}
</style>

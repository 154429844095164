<template>
  <AppAgendar></AppAgendar>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import AppAgendar from './AppAgendar.vue'
import { mdiAccount } from '@mdi/js';

export default {
  name: "AppHome",

  components: {
    SvgIcon,
    AppAgendar
  },

  data() {
    return {
      path: mdiAccount,
    }
  }
}
</script>
<style></style>
